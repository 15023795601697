import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import RegisterPanel from "../components/header/register-panel"

import imageTeamS2I1 from "../images/team-S2I1.jpg"
import imageTeamS3I1 from "../images/team-S3I1.jpg"
import imageTeamS4I1 from "../images/team-S4I1.jpg"
import imageTeamS5I1 from "../images/team-S5I1.jpg"

const TeamPage = () => {
  return (
    <Layout>

      <SEO title="Team" />

      <section className="scrollmagic-scene" id="team-scene-1">
        <div className="aspect"></div>
        <div className="content">

        	<div className="text-1">
        		<h1>The Team.</h1>
        	</div>

          <div className="text-2">
            <p>FLOW is the brainchild of Paul Gedoun, Director of S&S Projects who has a long history of creating award winning, luxury developments in Queensland. </p>
            <p>Paul loves Rainbow Bay and surfing. He wanted to create a place akin to a retreat, where you could pursue your passion, utterly energised and invigorated, always present in the moment and making the most of every minute – be it with family, friends or going it alone.</p>
          </div>

        </div>
      </section>

      <section className="scrollmagic-scene" id="team-scene-2">
        <div className="aspect"></div>
        <div className="content">

          <div className="text-1">
          	<h2>Developer</h2>
          </div>

          <div className="text-2">
	          <p><strong>Paul Gedoun<br /> Director, S&amp;S Projects</strong></p>

	          <p>S&S Project Developments is the evolution of a lifetime of construction and development experience purified over a long period down to a mixture of passion and desire.</p>

	          <p>The project profile and delivery approach is very different to the typical analysis as the drivers are not traditional. Quite simply the approach is mixing luxury with living, sensible designs for your ultimate lifestyle, resilient and stylish features designed for real people.</p>

	          <p>Paul has over 20 years’ experience in the construction and development industry, responsible for site acquisitions, planning, construction, design and delivery activities specialising in luxury apartments in the South Eastern corner of Queensland, from Townsville to the Gold Coast for over the last 12 years.</p>
	      	</div>

          <figure>
            <div role="img" style={{backgroundImage: 'url(' + imageTeamS2I1 + ')'}} data-tween="1" data-tweenfrom="1" data-x="-20%" />
          </figure>

	      	<div className="sands-logo svg--icon" role="img" aria-label="S&S Projects">
	      		<svg><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#sands-logo"></use></svg>
	      	</div>

        </div>
      </section>

      <section className="scrollmagic-scene" id="team-scene-3">
        <div className="aspect"></div>
        <div className="content">

          <div className="text-1">
          	<h2>Architect</h2>
          </div>

          <div className="text-2">
	          <p><strong>Cottee Parker</strong></p>

	          <p>Cottee Parker is a national multi-disciplinary architecture practice that consistently delivers creative design solutions for projects across Australia. Combining intellect with creativity, we focus on design excellence to create meaningful outcomes for our clients and the community. Our team understands the complexities of the property and development industry and how to create innovative, sustainable and commercially viable designs with a track record for delivering successful projects across Australia.</p>
	      	</div>

	      	<figure>
	      	  <div role="img" style={{backgroundImage: 'url(' + imageTeamS3I1 + ')'}} />
	      	</figure>

	      	<div className="logo svg--icon" role="img" aria-label="Cottee Parker">
	      		<svg><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#cottee-parker-logo"></use></svg>
	      	</div>

        </div>
      </section>

      <section className="scrollmagic-scene" id="team-scene-4">
        <div className="aspect"></div>
        <div className="content">

          <div className="text-1">
          	<h2>Interior Designer</h2>
          </div>

          <div className="text-2">
	          <p><strong>Cottee Parker</strong></p>

	          <p>Cottee Parker Interiors are experts in the multi-residential field, having delivered a diverse range of projects around Australia. We believe in a holistic, collaborative design approach that seamlessly weaves the exterior with the interior. We work closely with our architects to understand the site, its context and the building as it develops, mindful that even the smallest details are part of a larger story.</p>
	      	</div>

          <figure>
            <div role="img" style={{backgroundImage: 'url(' + imageTeamS4I1 + ')'}} />
          </figure>

	      	<div className="logo svg--icon" role="img" aria-label="Cottee Parker">
	      		<svg><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#cottee-parker-logo"></use></svg>
	      	</div>

        </div>
      </section>

      <section className="scrollmagic-scene" id="team-scene-5">
        <div className="aspect"></div>
        <div className="content">

          <div className="text-1">
          	<h2>Landscape Architect</h2>
          </div>

          <div className="text-2">
	          <p><strong>Dunn + Moran</strong></p>

	          <p>Established in 2011 by Steve Dunn and David Moran, Dunn + Moran Landscape Architects (DMLA) is a dedicated team of experienced, energetic and talented designers. We have a passion for life between buildings and landscape experience. The fine art of examining the way in which people live and connect with the built form and the landscape are defining characteristics of our professional endeavour.</p>
	      	</div>

          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 704 408">
            <defs>
              <clipPath id="clip-S5C1">
                <path d="M228.907,0l.006.013C110.493.013,12.958,89.361,0,204.318V408H704V0Z" style={{fill: 'none'}} />
              </clipPath>
            </defs>
            <g style={{clipPath: 'url(#clip-S5C1)'}}>
              <image width="3986" height="2306" transform="translate(-0.781 -0.415) scale(0.177 0.177)" xlinkHref={imageTeamS5I1} />
            </g>
          </svg>

	      	<div className="logo svg--icon" role="img" aria-label="Cottee Parker">
	      		<svg><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#dunn-moran-logo"></use></svg>
	      	</div>

        </div>
      </section>

      <section className="scrollmagic-scene" id="register-section">
        <RegisterPanel />
      </section>    

    </Layout>
  )
}

export default TeamPage
